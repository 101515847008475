export let uzmanliklar = [
"Acil Hekimliği",
"Adli Tıp",
"Aile Hekimliği",
"Algoloji",
"Allerji",
"Anestezi ve Reanimasyon",
"Ağız Yüz ve Çene Cerrahisi",
"Beyin Cerrahi",
"Biyokimya",
"Dahiliye",
"Deniz ve Sualtı Hekimligi",
"Dentoloji",
"Dermatoloji",
"Eczacı",
"Eczacı Teknisyeni",
"Endodonti",
"Endokrinoloji",
"Enfeksiyon Hastalıkları",
"Fizik Tedavi ve Rehabilitasyon",
"Fizyoloji",
"Gastro Cerrahi",
"Gastroenteroloji",
"Gelişimsel Pediyatri",
"Genel Cerrahi",
"Genel Tıp",
"Geriatri",
"Göğüs Cerrahi",
"Göğüs Hastalıkları",
"Halk Sağlığı",
"Hematoloji",
"Hemşirelik",
"Kadın Doğum",
"Kardiyoloji",
"Kardiyovasküler Cerrahi",
"Klinik Farmakoloji",
"Klinik Nörofizyoloji",
"Kulak Burun Boğaz",
"Mikrobiyoloji",
"Nefroloji",
"Neonatoloji",
"Nöro Psikiatri",
"Nöroloji",
"Nöropatoloji",
"Nükleer Tıp",
"Oftalmoloji",
"Onkoloji",
"Onkolojik Cerrahi",
"Ortopedi",
"Patoloji",
"Pediyatri",
"Pediyatrik Acil",
"Pediyatrik Cerrahi",
"Pediyatrik Endokrinoloji",
"Pediyatrik Enfeksiyon",
"Pediyatrik Gastroenteroloji",
"Pediyatrik Göğüs Hastalıkları",
"Pediyatrik Hematoloji",
"Pediyatrik Kardiyoloji",
"Pediyatrik Metabolizma",
"Pediyatrik Nefroloji",
"Pediyatrik Nöroloji",
"Pediyatrik Onkoloji",
"Pediyatrik Psikiyatri",
"Pediyatrik Romatoloji",
"Pediyatrik Üroloji",
"Pediyatrik İmmünoloji",
"Pediyatrik İmmünoloji ve Allerji",
"Plastik Cerrahi",
"Psikiyatri",
"Radyasyon Onkolojisi",
"Radyoloji",
"Romatoloji",
"Sitopatoloji",
"Temel İmmünoloji",
"Tıbbi Ekoloji ve Hidroklimatoloji",
"Tıbbi Genetik",
"Yoğun Bakım",
"Üroloji",
"İmmünoloji ve Allerji Hastalıkları",
"İş ve Meslek Hastalıkları"
];
