import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import InHeader from "../components/InHeader";
import Footer from "../components/Footer";
import Waveform from "../components/Waveform";

const _ = require("lodash");
const { REACT_APP_API_URL } = process.env;

const axios = require("axios");

export default function Podcastler(props) {
  var aylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
  const tarihGoster = (date) => {
    var tarih = new Date(date);
    var ay = tarih.getMonth();
    var yil = tarih.getFullYear();
    return aylar[ay] + " " + yil;
  };
  

  let { slug } = useParams();

  const [icerik, setIcerik] = useState({});
  const [liste, setListe] = useState([]);
  const [documentType, setDocumenttype] = useState(null);

  const getContent = (section, extra) => {
    axios
      .get(REACT_APP_API_URL + section + "?_embed" + extra)
      .then(function (response) {
        if (response.data.length > 0) {
          setListe(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getContent("podcastler");
    setDocumenttype("podcastler");

  }, []);

  useEffect(() => {
    if (liste.length > 0) {
      if (slug) {
        const foundContent = _.find(liste, function (data) {
          return data.slug === slug;
        });
        if (foundContent) {
          setIcerik(foundContent);
        }
      } else {
        setIcerik(liste[0]);
      }
    }
  }, [liste]);

  useEffect(() => {
    if (slug && liste.length > 0) {
      const foundContent = _.find(liste, function (data) {
        return data.slug === slug;
      });
      if (foundContent) {
        setIcerik(foundContent);
      }
    }
  }, [slug]);

  return (
    <div>
      <InHeader type="podcast" />
      <section className="container-fluid thepodcast">
        <div className="container">
          <main>
            <section>
              <div className="row">
                <div className="col mt-5 mb-5">
                  <h1 className="display-2 text-center">PODCASTLER</h1>
                </div>
              </div>

            </section>

            <section className="mb-5">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 ps-lg-5">
                  <div className="row ">
                    <div className="col">
                    <Waveform  />
                      {icerik && Object.keys(icerik).length > 0 && (
                        <div>
                          {icerik._embedded && icerik._embedded["wp:featuredmedia"] && (
                            <img className="img-fluid mb-5" src={icerik._embedded["wp:featuredmedia"][0].source_url} alt={icerik.title.renreder} />
                          )}
                          <h1 className="anatitle">{icerik.title.rendered}</h1>
                          <div  dangerouslySetInnerHTML={{ __html: icerik.content.rendered }} />
                        </div>
                      )}

 
                      {Object.keys(icerik).length === 0 && (
                        <div>
                          <h1 className="display-3">İçerik bulunamadı</h1>
                          <div>Aradığınız içerik bulunamadı. Lütfen daha farklı parametreler ile tekrar deneyin.</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <ul className="navbar-nav analist">
                    {liste.map((l, i) => {
                      return (
                        <li key={i} className={`item ${slug && slug === l.slug ? "active" : ""}`}>
                          <Link to={`/${documentType}/${l.slug}`}>
                            <h4 className="text-uppercase">{l.title.rendered}</h4>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </section>
          </main>
        </div>
      </section>
      <Footer />
    </div>
  );
}
