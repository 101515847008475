import logo_giris from "../assets/img/logo_giris.png";
import send from "../assets/img/send.png";
import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import back from "../assets/img/back.png";
import { sendEvent } from "../tools/analytics";

const _ = require('lodash');
const { REACT_APP_TAKEDA_API_URL } = process.env;

class Sifremisifirla extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      errors: null,
      loading: false,
      stateToken: null,
      newPassword: "",
      success : false,
    };
    this.onSend = this.onSend.bind(this);
    this.input_newPassword = React.createRef();
  }

  CheckPassword = (inputtxt) => { 
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    if(inputtxt.match(passw)) { 
        return true;
    }else{ 
        return false;
    }
}


  componentDidMount() {
    this.setState({ stateToken: this.props.match.params.stateToken, username: this.props.match.params.email });

    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        this.onLogin();
      }
    };
    document.addEventListener("keydown", listener);
  }

  onSend = () => {
    this.setState({ errors: null });

    if (this.state.newPassword === "") {
      let us = this.input_newPassword.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Yeni şifrenizi yazmadınız";
      us.nextSibling.classList.add("error");
    }else if(!this.CheckPassword(this.state.newPassword)){
      let us = this.input_newPassword.current; 
      us.classList.add('is-invalid');
      us.nextSibling.innerHTML = "Şifreniz en az 1 rakam, 1 büyük harf barındırmalı ve en az 8 karakterden oluşmalıdır. ";
      us.nextSibling.classList.add('error');
  } else {
      this.setState({ errors: null, loading: true });

      let data = {
        email: this.state.username,
        stateToken: this.state.stateToken,
        newPassword: this.state.newPassword,
      };

      var self = this;
      axios
        .post(REACT_APP_TAKEDA_API_URL + "reset_password", data)
        .then(function (response) {
          self.setState({success :true});
          //sendEvent({category: 'User',action: 'Kullanıcı giriş yaptı.'});
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            switch (error.response.data.errorCode) {
              case "E0000011":
                self.setState({ errors: "E-Postanıza gelen link artık geçerli değil. Muhtemelen kullanım süresi doldu. Yeniden şifrenizi sıfırlamanız gerekmektedir.", loading: false });
                break;
              case "E0000080":
                self.setState({ errors: "Girdiğiniz şifre yeterince karmaşık değil. ", loading: false });
                break;
              default:
                self.setState({ errors: "Bir hata oluştu", loading: false });
                break;
            }
          } else {
            self.setState({ errors: "Bağlantı hatası. Sunucu ile iletişim kurulamıyor.", loading: false });
          }
        });
    }
  };

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    if (nam === "newPassword" && val.length > 0) {
      let us = this.input_newPassword.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Yeni Şifreniz";
      us.nextSibling.classList.remove("error");
    }
    this.setState({ [nam]: val });
  };
  render() {
    return (
      <div className="loginregister container justify-content-center d-flex flex-column pt-5 pb-5 ">
        <div className="float-start position-absolute mt-3" style={{ top: 0 }}>
          <Link to={"/login"}>
            <img src={back} alt="Geri" />
          </Link>
        </div>
        <div className="row">
          <div className="col text-center">
            <img alt="logo_giris" className="img-fluid" src={logo_giris} />
          </div>
        </div>
        <div className="row mt-5">
          <div className="d-flex flex-column align-items-center w-100">
            <div className="col-lg-8 d-flex flex-column align-items-center">
              <h2>Takeda ID Şifremi Sıfırlama</h2>
              <br />

              {this.state.success && <p className='text-center'>Şifreniz değiştirildi. Giriş yapmak için <b><Link to='/login'>tıklayınız.</Link></b></p>}

              { !this.state.success && 
              <p className="text-center">
                Lütfen <b>{this.state.username}</b> kullanıcısı için belirlemek istediğiniz yeni şifrenizi girin.{" "}
              </p>
              }
              {this.state.errors && <p className="error">{this.state.errors}</p>}


              { !this.state.success && 
              <div className="form-floating mb-5 mt-5" style={{ width: "100%" }}>
                <input
                  type="text"
                  ref={this.input_newPassword}
                  name="newPassword"
                  onChange={this.myChangeHandler}
                  id="newPassword"
                  placeholder="Yeni Şifreniz"
                  className="form-control"
                />
                <label htmlFor="newPassword">Yeni Şifreniz</label>
              </div>
              }
              { !this.state.success && 
              <div className="w-100 row justify-content-between align-items-center">
                <div className="col-xl-12 d-flex justify-content-end mb-5">
                  {this.state.loading && <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                  {!this.state.loading && (
                    <button onClick={this.onSend}>
                      {" "}
                      <img src={send} alt="Gönder" />{" "}
                    </button>
                  )}
                </div>
              </div>
              }
            
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Sifremisifirla);
