import React from "react";
import InHeader from "../components/InHeader";
import Footer from "../components/Footer";

export default function VakaHavuzu(props) {
  return (
    <section className="container-fluid">
      <InHeader type={props.type} />
      <div className="container">
        <main>

            <h1 className="display-2 r-font mt-5 text-center">{props.title}</h1>
            <p className='mb-5 text-center' style={{fontSize : 24}}>Multipl Miyelom hastalarının öyküleriyle, tanı ve tedavi süreçlerini içeren medikal yolculuklarına interaktif olarak misafir olabilirsiniz.</p>


          <div className="row">
            <div style={{ position: "relative", display: "block" }}>
              <div style={{ paddingTop: "57.3%" }}>
                <iframe
                  title="Vaka Havuzu"
                  src="//www.ajansdigital.com/apps/takeda/yolculugum_mt"
                  allowFullScreen
                  style={{ position: "absolute", top: 0, right: 0, bottom: 0, left: 0, width: "100%", height: "100%" }}
                ></iframe>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </section>
  );
}
