import '../passets/css/styles.scss';
import lax from '../passets/js/lax.js';
import { imagePath, sahneler } from "../assets/sahneler.js";
import giris_buton from "../passets/images/giris_buton.png";
import fare_icon from "../passets/images/fare_icon.png";
import React, { useRef ,useEffect,useContext ,useState} from 'react';
import { Link } from "react-router-dom";

import {s_giris} from "../passets/js/giris";
import {s_fuaye} from "../passets/js/fuaye";
import {s_salon} from "../passets/js/salon";
import { useCookies } from 'react-cookie';
import UserContext from '../UserContext'

export default function Parallax() {
    
  const [myPlayer, setMyplayer] = useState(null);
  const [videoVersion,setVideoversion] = useState(1);
  const [videoId,setVideoid]  = useState(6267458141001);
  const [cookies, setCookie] = useCookies(['enterprise_id']);

  useEffect(() => {

    const script = document.createElement("script");
    script.src = "//players.brightcove.net/3871979173001/EpKcDYKhQY_default/index.min.js";
    script.async = true;
    script.onload = () => {
      window.videojs.getPlayer('theBC').ready(function() {
        setMyplayer(this);
      });
    }
    document.body.appendChild(script);

    document.body.classList.add('parallax');
    return function cleanup() {
      window.removeEventListener("resize", adjustPadding);
      document.body.classList.remove('parallax');
    }
  },[]);


  const user = useContext(UserContext);

  const videoFrame = useRef(null);
  const video = useRef(null);
  const giris = useRef({current:null});
  const fuaye = useRef({current:null});
  const salon = useRef({current:null});

  var theVid;
  var vidParent;

  var ilksahne = 1000; 

  function videoActive() {
    video.current.style.display = "block";
    setTimeout(function () {
      video.current.classList.add('video_active');
    }, 100);

    document.getElementById('all').classList.add('hideOpacity');
   //theVid.play();
  }

  function changeVideo(id) {
    myPlayer.catalog.getVideo(id, function(error, video) {
      myPlayer.catalog.load(video);
      myPlayer.play();
    });
  }

  function changeVersion(event){
    setVideoversion(event.target.value);
    if(event.target.value === "1"){
      changeVideo(6267458141001);
    }else if(event.target.value === "2"){
      changeVideo(6267452775001);
    }

  }


  function girisyap() {
    let pop = document.getElementsByClassName('popup');
    pop[0].style.display = "none";
    document.getElementsByTagName("BODY")[0].style.overflow = "auto";

    lax.init();

    lax.addDriver("scrollY", function () {
      return window.scrollY;
    });

    lax.addElements(".mainScale", {
      scrollY: {
        translateZ: [
          [0, 3000],
          [0, 400],
        ],
      },
    });

    s_giris();

    s_fuaye();

    s_salon();
  };

  function debounce(func){
    var timer;
    return function(event){
      if(timer) clearTimeout(timer);
      timer = setTimeout(func,500,event);
    };
  }


window.addEventListener("resize",debounce(adjustPadding));

function adjustPadding(){
    
  var tumu = document.getElementById("all");
  if(tumu){
   tumu.style.top = (window.innerHeight - document.getElementById("thebaz").offsetHeight) / 2 + "px";
   tumu.style.height = document.getElementById("thebaz").offsetHeight + "px";

   var fuaye = document.getElementById("fuaye");
   fuaye.style.height = document.getElementById("thebaz2").offsetHeight + "px";

   var salon = document.getElementById("salon");
   salon.style.top = "-" + document.getElementById("thebaz3").offsetHeight + "px";
   salon.style.height = document.getElementById("thebaz3").offsetHeight + "px"; 
   
 }

}

  useEffect(() => {
    setTimeout(function () { window.scrollTo(0, 0); adjustPadding(); }, 500);
  },[]);

 
  return (
    <div className='parallax'>

      <div id='portals'>
        <Link to='/'>Portala devam et</Link>
      </div>

      <div className='popup'>
        <div className='inside'>
          <img src={fare_icon} alt="Fare" />
          <div>
            <p>Multipl Miyelom hastalarının umut dolu mücadelelerine tanık olacağımız sıra dışı bir yolculuğa hoş geldiniz.</p>
            <p>Parallax altyapılı bu web sitesini masaüstü cihazlarda sitenin sağ tarafındaki kaydırma çubuğunu ya da bilgisayar faresinin üzerindeki kaydırma çubuğunu aşağı indirerek görüntüleyebilirsiniz.</p>
            <p>Mobil cihazlarda ise sayfayı aşağı doğru kaydırarak deneyimleyebilirsiniz.</p>
          </div>
          <button onClick={girisyap}>
            <img src={giris_buton} alt="Giriş Yap" />
          </button>
        </div>
      </div>

      <div id="main">

        <div style={{ 'zIndex': 1 }} id='thevideo2' ref={video}>
          <video-js
            data-video-id={videoId}
            data-account="3871979173001"
            data-player="EpKcDYKhQY"
            data-embed="default"
            controls=""
            data-application-id=""
            data-bc-custom-guid={cookies.enterprise_id}
            class="vjs-fluid"
            id="theBC">
          </video-js>

          <select name='videoVersions' id='videoVersions' onChange={changeVersion} value={videoVersion}>
            <option value='1'>Orijinal</option>
            <option value='2'>İngilizce Altyazılı</option>
          </select>
        </div>

        <div className="mainScale">
          <div id="all">

        {sahneler.map((sahne, i) => {

            var sonuc = [];

              if(sahne.sahneAdi === "salon" && i === 2){
                sonuc.push( 
                <div style={{ 'zIndex': 3 }} >
                  <button onClick={videoActive}>
                    <img src={`${process.env.PUBLIC_URL}/sinema/salon/thevideo.webp`} id='thevideo' alt="Video" />
                  </button>
                </div>);
              }

              sahne.kareler.map((kare, a) => {
                var sc1 = {};
                var img = {};
                var sc2 = {};

                if(user.userType === "halk"){
                  if(kare.className === "tabela"){
                    kare.file = "4-tabela_halk";
                  }
                  if(kare.className === "giseon"){
                    kare.file = "6-gise-on_halk";
                  }
                }

                img.src = imagePath + sahne.sahneAdi + "/small/" + kare.file + "_1x.webp";
                img.className = kare.className;

                sc1.srcSet = imagePath + sahne.sahneAdi + "/" + kare.file + ".webp";
                sc1.type = "image/webp";
                sc1.media = "(min-width: 800px)";

                sc2.srcSet =  imagePath + sahne.sahneAdi + "/small/" + kare.file + "_1x.webp";
                sc2.type = "image/webp";
                sc2.media = "(max-width: 800px)";

                if (kare.type === "div") {
                  sonuc.push(<div style={kare.style} key={a}>
                    <picture>
                      <source media={sc1.media} srcSet={sc1.srcSet} type={sc1.type}  />
                      <source media={sc2.media} srcSet={sc2.srcSet} type={sc2.type}  />
                      <img src={img.src} className={img.className} alt={kare.className} />
                    </picture>
                  </div>);
                } else if (kare.type === "img") {
                  sonuc.push(
                  <picture key={a}>
                    <source media={sc1.media} srcSet={sc1.srcSet} type={sc1.type}  />
                    <source media={sc2.media} srcSet={sc2.srcSet} type={sc2.type} />
                    <img src={img.src} className={img.className} style={kare.style} id={kare.id} alt={img.className} />
                  </picture>)
                }
                return kare;
              });
              return <div className={sahne.sahneAdi} id={sahne.sahneAdi} key={i} >{sonuc}</div>  
            }) 
          }

          </div>
        </div>
      </div>
    </div>

  );

}