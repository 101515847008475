import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import VakaHavuzu from "../components/VakaHavuzu";
import Podcastler from "../components/Podcastler";
import CanliYayin from "../components/CanliYayin";
import Roportajlar from "../components/Roportajlar";
import Bultenler from "../components/Bultenler";
import Miyeloms from "../components/Miyeloms";
import BesAsama from "../components/BesAsama";
import Analogo from "../components/Analogo";
import Sahne from "../components/Sahne";
import Backstage from "../components/Backstage";
import HalkBottom from "../components/HalkBottom";

import React, { useContext, useEffect } from "react";
import UserContext from "../UserContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/style.css";

export default function Anasayfa() {
  const user = useContext(UserContext);

  useEffect(() => {
    const { hash } = window.location;

    if (!hash) return;

    window.requestAnimationFrame(() => {
      const anchor = document.querySelector(hash);
      if (anchor) {
        const offset = anchor.getBoundingClientRect().top + window.scrollY;
        window.scroll({ top: offset, left: 0 });
      }
    });
  }, []);

  if (user.userType === "halk") {
    return (
      <div id="anasayfa">
        <Header />
        <main>
          <Analogo />
          <Miyeloms />
          {/* <HalkBottom /> */}
        </main>
        <Footer />
      </div>
    );
  } else {
    if (user.hcpType === "onkolog") {
      return (
        <div id="anasayfa">
          <Header />
          <main>
            <Sahne />
            {/* <Analogo />
            <Roportajlar />
            <Podcastler />
            <VakaHavuzu />
            <CanliYayin />
            <Miyeloms />
            <Bultenler />
            <Backstage />
            <BesAsama />
            <Banner /> */}
          </main>
          <Footer />
        </div>
      );
    } else {
      return (
        <div id="anasayfa">
          <Header />
          <main>
            <Sahne />
            { /* <Analogo />
            <Roportajlar />
            <Miyeloms />
            <Bultenler />
            <Backstage />
            <BesAsama />
            <Banner /> */
            }
          </main>
          <Footer />
        </div>
      );
    }
  }
}
