import React, { useEffect } from "react";

export default function Test() {


  return (
    <div style={{ maxWidth: "960px" }}>
      <video-js
        data-video-id="6267458141001"
        data-account="3871979173001"
        data-player="EpKcDYKhQY"
        data-embed="default"
        controls=""
        data-application-id=""
        data-bc-custom-guid="TUR-TEST_TEST_TEST"
        data-bc-trackingid="TUR-TEST_TEST_TEST"
        class="vjs-fluid"
        style={{ paddingTop: "56.25%" }}
      ></video-js>
    </div>
  );
}
