import React, { useState, useEffect } from "react";
import mic from "../assets/img/podcasts_mic.png";
import Tilt from "react-tilt";
import listen from "../assets/img/play.png";
import { Link } from "react-router-dom";
import logobas from "../assets/img/logobas.png";

const { REACT_APP_API_URL } = process.env;
const axios = require("axios");

export default function Podcastler() {
  var aylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

  const [podcasts, setPodcasts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(REACT_APP_API_URL + "podcastler?per_page=1")
      .then(function (response) {
        let myArr = response.data[0];
        setPodcasts(myArr);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  if (podcasts.content) {
    var d = new Date(podcasts.date);
  }
// <h3 className="subtitle text-uppercase">{aylar[d.getMonth()] + " " + d.getFullYear()} </h3>
  return (
    <section id="podcast" className="container pt-lg-5 pb-lg-5 mt-5 mb-5">
      <div className="row">
        <div className="col-12">
          <Link to="/podcastler" className="titleAnchor justify-content-center">
            <img src={logobas} alt='seperator' />
            <h1 className="title r-font">PODCASTLER</h1>
          </Link>
        </div>
        <div className="col-12 col-sm-6 pb-4">
          <Link to="/podcastler">
            <Tilt className="Tilt col" options={{ max: 30, scale: 1.0 }}>
              <div className="Tilt-inner">
                <img src={listen} alt="Röportaj detayı" className='rightBottomIcon' />
                <img className="img-fluid mx-auto d-table pe-5 img-mobil-ana" src={mic} alt="Mikrofon" />
              </div>
            </Tilt>
          </Link>
        </div>
        <div className="col-12 col-sm-6" id="last_podcast">
          {loading && (
            <div className="lds-spinner" style={{ margin: "0 auto" }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          )}
          {podcasts.content && (
            <div>
              <h6 className="bold text-uppercase">
                <Link to="/podcastler"> </Link>
              </h6>
              <p className="font30" dangerouslySetInnerHTML={{ __html: podcasts.content.rendered }} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
