import React, { useContext,useEffect }  from 'react';
import back from "../assets/img/back.png";
import back_halk from "../assets/img/back_halk.png";
import back_mobile from "../assets/img/back_mobile.png";
import back_mobile_halk from "../assets/img/back_mobile_halk.png";
import logo_menu2 from "../assets/img/logo_menu2.png";
import logo_halk from "../assets/img/logo_menu2_halk.png";

import {Link} from "react-router-dom";
import UserContext from '../UserContext'

export default function InHeader(props) {
    //const [showMenu, setshowMenu] = useState(false);
    const user = useContext(UserContext);

    let backLink = "";

    switch (props.type) {
        case "miyelom":
          backLink = "#multipl"
          break;
        case "roportaj":
          backLink = "#roportajlar"
          break;
        case "vakahavuzu":
          backLink = "#vakahavuzu"
          break;
        case "podcast":
          backLink = "#podcast"
          break;
        default:
  
          break;
      }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
      <header className="border-bottom border-dark p-3">
          <div className="container-fluid">
              <div className="float-start position-absolute d-none d-md-block mt-3">
                  <Link to={'/'+backLink}><img src={user.userType === "halk" ? back_halk : back} alt="Arka" /></Link>
              </div>
              <Link to={'/'+backLink} className="d-flex justify-content-center align-items-center">
                <img className="d-md-none" src={user.userType === "halk" ? back_mobile_halk : back_mobile} alt="Geri Dön" />
                <img className="center-img img-fluid" src={user.userType === "halk" ? logo_halk : logo_menu2} alt="Logo menü" />
              </Link>
          </div>
      </header>
        );
}