import lax from './lax.js';

var ilksahne = 1000; 

var aralik = [0,1500];

export var s_giris = function () {
  lax.addElements(".giris", {
    scrollY: {
      opacity: [
        [aralik[1]-200, aralik[1]],
        [1, 0],
      ],
    },
  });

  lax.addElements(".lamba",
    {
      scrollY: {
        translateZ: [
          [0, aralik[1]],
          [0, "elWidth/12"],
        ],
      },
    }
  );

  lax.addElements(".tabela", {
    scrollY: {
      translateZ: [
        [0, ilksahne],
        [0, "elWidth/20"],
      ],
      translateY: [
        [0, ilksahne],
        [0, "elWidth/50"],
      ],
    },
  });

  lax.addElements(".buyuksaksi", {
    scrollY: {
      translateZ: [
        [0, aralik[1]],
        [0, "elWidth/20"],
      ],
    },
  });

  lax.addElements(".taksi", {
    scrollY: {
      translateZ: [
        [0, aralik[1]],
        [0, "elWidth/4"],
        {easing : "easeOutQuad" }
      ],
      translateX: [
        [0, 500],
        ["elWidth/2", "-elWidth/7"],
      ],
    },
  });

  lax.addElements(".kus", {
    scrollY: {
      scale: [
        [0, aralik[1] ],
        [1, 0.5],
      ],
      translateX: [
        [0,aralik[1]/1.5],
        [-400, "screenWidth/4"],
      ],
      translateY: [
        [0, aralik[1]/1.5],
        [200, -1000],
      ],
      skewX: [
        [0, aralik[1]],
        [0, -30],
      ],
    },
  });

  lax.addElements(".agac", {
    scrollY: {
      translateZ: [
        [0, aralik[1]],
        [0, "elWidth/4"],
      ],
    },
  });

  lax.addElements(".solkapi", {
    scrollY: {
      translateX: [
        [650, 950, 1000],
        [0, "-elWidth/10", "-elWidth/2"],
      ],
    },
  });

  lax.addElements(".sagkapi", {
    scrollY: {
      translateX: [
        [650, 950, 1000],
        [0, "elWidth/10", "elWidth/2"],
      ],
    },
  });
}
