import React from "react";

export default function Modal(props) {

  return (
    <div>
      {props.data.modalOpen && <div className={`modal-backdrop fade show`} onClick={props.closePopup}></div>}
      <div
        className={`modal fade ${props.data.modalOpen ? "show" : ""}`}
        style={props.data.modalStyle}
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered ${props.data.xl === "xl" ? "modal-xl" : "modal-lg"}`} style={props.data.modalOpen ? { pointerEvents: "auto" } : {}}>
          <div className="content" style={{width: '100%'}}>{props.data.modalContent}</div>
        </div>
      </div>
    </div>
  );
}
