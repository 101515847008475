import logo from "../assets/img/logo.png";
import logo_mobil from "../assets/img/logo_mobil.png";
import logo_halk from "../assets/img/logo_halk.png";
import logo_mobil_halk from "../assets/img/logo_mobil_halk.png";

import React, { useContext } from "react";
import UserContext from "../UserContext";

export default function Analogo() {
  const user = useContext(UserContext);

  return (
    <section id="index" className="container py-lg-5 text-center mb150">
      <div className="row align-items-center">
        <div className="d-block">
          <img className="img-fluid mx-auto d-none d-sm-block" src={user.userType === "halk" ? logo_halk : logo} alt="Logo" />
          <img className="img-fluid d-sm-none" src={user.userType === "halk" ? logo_mobil_halk : logo_mobil} alt="Logo mobil" />
        </div>
      </div>
    </section>
  );
}
