import logo_giris from "../assets/img/logo_giris.png";
import React from "react";
import {withRouter } from "react-router-dom";

class UyelikTamam extends React.Component {

  render() {
    return (
      <div className="loginok container justify-content-center d-flex flex-column pt-5 pb-5 " >
        <div className="row">
          <div className="col text-center">
            <img alt="logo_giris" className="img-fluid" src={logo_giris} />
          </div>
        </div>

          <div className="row mt-5">
            <div className="d-flex flex-column align-items-center w-100">
              <div className="col-lg-8 d-flex flex-column align-items-center">
                <h2 className='mb-5'>Üyelik Başarılı</h2>
                <p className='text-center'>Üyeliğiniz oluşturuldu. Lütfen hesabınızı aktifleştirmek için üye olurken kullandığınız e-posta adresinizi kontrol ediniz. Güvenlik sebebi ile E-posta adresinize gelen e-postadan üyeliğinizi aktifleştirmeniz gerekmektedir. </p>
                <br />
                <button className='dabutton' onClick={()=> {this.props.history.push("/login")}  }>Giriş Yap</button>
              </div>
            </div>
          </div>

      </div>
    );
  }
}

export default withRouter(UyelikTamam);