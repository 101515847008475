import React, { useState, useContext } from "react";
import logo_menu from "../assets/img/logo_menu.png";
import logo_menu_halk from "../assets/img/logo_menu_halk.png";
import { Link } from "react-router-dom";
import UserContext from "../UserContext";

export default function Header() {
  const [showMenu, setshowMenu] = useState(false);
  const [showSub, setShowsub] = useState(false);

  const user = useContext(UserContext);

  return (
    <header className="sticky-xs-top">
      <nav className="navbar navbar-expand-xl navbar-dark bg-dark">
        <div className="container">
          <div className="mobileRoot">
            <Link className="navbar-brand" to="/">
              <img src={user.userType === "halk" ? logo_menu_halk : logo_menu} alt="Logo menü" style={{ maxWidth: "100%" }} />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setshowMenu(!showMenu)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className={`collapse navbar-collapse justify-content-end w-100 ${showMenu ? "show" : ""}`} id="navbarText">
            <ul className="navbar-nav">

              {/* 
              {user.userType !== "halk" && (
                <li className="nav-item">
                  <a className="nav-link" href="#roportajlar">
                    Videolar
                  </a>
                </li>
              )}
              {user.userType !== "halk" && user.hcpType !== "diger" && (
                <li className="nav-item">
                  <a className="nav-link" href="#podcast">
                    Podcast
                  </a>
                </li>
              )}
              {user.userType !== "halk" && user.hcpType !== "diger" && (
                <li className="nav-item">
                  <a className="nav-link" href="#vakahavuzu">
                    Vaka Havuzu
                  </a>
                </li>
              )}
              {user.userType !== "halk" && user.hcpType !== "diger" && (
                <li className="nav-item">
                  <a className="nav-link" href="#webinar">
                    Webinar
                  </a>
                </li>
              )}
              <li className="nav-item">
                <a className="nav-link" href="#multipl">
                  Multipl Miyelom
                </a>
              </li>
              {user.userType === "halk" && (
                <li className="nav-item">
                  <a className="nav-link" href="#harita">
                    TR Hematoloji Haritası
                  </a>
                </li>
              )}

              {user.userType !== "halk" && user.hcpType === "diger" && (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href="#bulten">
                      Bülten
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#backstage">
                      Backstage
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#sadness">
                      Üzüntünün 5 Aşaması
                    </a>
                  </li>
                
                */}
                <li className="nav-item justify-content-end">
                 {/* <li className="nav-item"> */}
                    <button className="nav-link" onClick={() => user.setUser(null)}>
                      Çıkış Yap
                    </button>
                  </li>
             {/*   </>
              )}
            */}

            {/*
              {user.userType === "halk" && (
                <li className="nav-item">
                  <button className="nav-link" onClick={() => user.setUser(null)}>
                    Çıkış Yap
                  </button>
                </li>
              )}
              {user.userType !== "halk" && user.hcpType !== "diger" && (
                <li className="nav-item dropdown">
                  <button
                    className={`nav-link dropdown-toggle`}
                    onClick={() => {
                      setShowsub(!showSub);
                    }}
                    aria-expanded="false"
                  >
                    Diğer
                  </button>
                  <ul className={`dropdown-menu ${showSub ? "show" : ""}`} aria-labelledby="navbarDropdown">
                    {user.userType !== "halk" && (
                      <li>
                        <a className="dropdown-item" href="#backstage">
                          Backstage
                        </a>
                      </li>
                    )}
                    {user.userType !== "halk" && (
                      <li>
                        <a className="dropdown-item" href="#bulten">
                          Bülten
                        </a>
                      </li>
                    )}
                    {user.userType !== "halk" && (
                      <li>
                        <a className="dropdown-item" href="#sadness">
                          Üzüntünün 5 Aşaması
                        </a>
                      </li>
                    )}
                    <li>
                      <button className="dropdown-item" onClick={() => user.setUser(null)}>
                        Çıkış Yap
                      </button>
                    </li>
                  </ul>
                </li>
              )}

                    */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
