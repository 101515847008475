import React, { useState, useEffect } from "react";
import roportaj from "../assets/img/roportaj.png";
import logobas from "../assets/img/logobas.png";
import Tilt from "react-tilt";
import { Link } from "react-router-dom";
import click_and_watch from "../assets/img/click_and_watch_orange.png";
const axios = require("axios");
const { REACT_APP_API_URL } = process.env;

export default function Roportajlar() {
  /* const [roportajlar, setRoportajlar] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(REACT_APP_API_URL + "roportajlar?per_page=1")
      .then(function (response) {
        setLoading(false);
        setRoportajlar(response.data[0]);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  }, []);*/

  return (
    <section id="roportajlar" className="container pb-5 mt-lg-5 mb-lg-5">
      <div className="row justify-content-center">
        <div className="col-12">
          <Link to="/roportajlar" className='titleAnchor'>
            <img src={logobas} alt='seperator' /><h1 className="title r-font">VİDEOLAR</h1>
          </Link>
        </div>
        <div className="col-xl-5" id="last_interviews">

         <div>
          
            <h6 className="bold text-uppercase">
              <Link to='/roportajlar' > </Link>
            </h6>
            <p className="font30">Multipl Miyelom alanında KOL hekimlerimizin ve önde gelen uzmanlarımızın bilgilendirici videolarına, yurt dışındaki saygın hekimlerin röportajlarına ve daha birçok ilgi çekici içeriğe bu bölümden ulaşabilirsiniz.</p>
        </div>
        </div>
        <div className="col-xl-5">
        <Link to='/roportajlar' >
          <Tilt className="Tilt col" options={{ max: 30, scale: 1.0 }}>
            <div className="Tilt-inner">
              <img src={click_and_watch} alt="Röportaj detayı" className='leftBottomIcon' />
              <img className="img-fluid img-mobil-ana" src={roportaj} alt="Ropörtaj" />
            </div>
          </Tilt>
          </Link>
        </div>
      </div>
    </section>
  );
}
