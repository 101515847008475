import React, { Component } from 'react';
import WaveSurfer from 'wavesurfer.js';
import play from "../assets/img/play.png";
import pause from "../assets/img/pause.png";
import UserContext from "../UserContext";

import { WaveformContianer, Wave } from './Waveform.styled';

class Waveform extends Component {  

  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.waveRef = React.createRef();
  }

  componentDidMount() {
    const url = 'https://www.mfiles.co.uk/mp3-downloads/gs-cd-track2.mp3';
    var self = this;

    if(self.context.waveSurfer === null){
      self.context.setWavesurfer(
        WaveSurfer.create({
          barWidth: 3,
          cursorWidth: 3,
          container: "#waveform",
          backend: 'WebAudio',
          height: 200,
          progressColor: '#e06f3c',
          responsive: true,
          waveColor: '#EFEFEF',
          cursorColor: 'transparent'
        })
      ).then( function(){
        self.context.waveSurfer.load(url);
      });
    }else{
      console.log(this.context.waveSurfer.container);
      this.waveRef.current.appendChild(this.context.waveSurfer.container);
    }

    };
  
  handlePlay = () => {
    if(this.context.podcastPlaying){
      this.context.waveSurfer.pause();
      this.context.setPodcastplaying(false);
    }else{
      this.context.waveSurfer.play();
      this.context.setPodcastplaying(true);
    }
  };

  handlePause = () => {
    this.context.waveSurfer.pause();
  }
  
  render() {
    return (
      <WaveformContianer className='mb-5 mt-5'>
        <img className='anaplayPause' alt='play-pause' onClick={this.handlePlay} src={!this.context.podcastPlaying ? play : pause} />
        <Wave id="waveform" ref={this.waveRef} />
      </WaveformContianer>
    );
  }
};

export default Waveform;