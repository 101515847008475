import React, { useState, useEffect } from "react";
import yolculugum from "../assets/img/yolculugum.png";
import more_info from "../assets/img/more_information_orange.png";
import logobas from "../assets/img/logobas.png";
import { Link } from "react-router-dom";
import Tilt from "react-tilt";
const axios = require("axios");
const { REACT_APP_API_URL } = process.env;

export default function Bultenler() {
  const [lastBulten, setlastBulten] = useState(null);
  var aylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];

  useEffect(() => {
    axios
      .get(REACT_APP_API_URL + "yolculugummag?per_page=1")
      .then(function (response) {
        setlastBulten(response.data[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  if (lastBulten) {
    var d = new Date(lastBulten.date);
  }

  //<h3 className="subtitle text-uppercase">{aylar[d.getMonth()] + "  " + d.getFullYear()} </h3>

  return (
    <section id="bulten" className="container pt-lg-5 pb-5 mt-5 mb-lg-5">
      <div className="row justify-content-center">
        <div className="col-12">
          <Link to="/yolculugum-mag" className="titleAnchor justify-content-center">
            <img src={logobas} alt='seperator' />
            <h1 className="title r-font">YOLCULUĞUMMAG</h1>
          </Link>
        </div>
        <div className="col-12 col-md-6 col-xl-4  pb-4">
          <Link to="/yolculugum-mag">
            <Tilt className="Tilt" options={{ max: 20, scale: 1.0, reverse: true }}>
              <div className="Tilt-inner">
                <img src={more_info} alt="YolculuğumMag" className='leftBottomIcon' />
                <img className="img-fluid img-mobil-ana" src={yolculugum} alt="Yolculuğummag" />
              </div>
            </Tilt>
          </Link>
        </div>
        <div className="col-12 col-md-6 col-xl-4" id="last_bulletin">
          {lastBulten && (
            <div>
              <p className="font30" dangerouslySetInnerHTML={{ __html: lastBulten.content.rendered }} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
