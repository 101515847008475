import logo_giris from "../assets/img/logo_giris.png";
import React from "react";
import { withRouter } from "react-router-dom";


class Aydinlatma extends React.Component {
  render() {
    return (
      <div className="aydinlatma container justify-content-center d-flex flex-column pt-5 pb-5 ">
        <div className="row">
          <div className="col text-center">
            <img alt="logo_giris" className="img-fluid" src={logo_giris} />
          </div>
        </div>

        <div className="row mt-5">
          <div className="d-flex flex-column align-items-center w-100">
            <div className="col-lg-12 d-flex flex-column align-items-start text-start">
              <h2>Takeda Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni</h2>

              <p>
                İşbu Aydınlatma Metni, Takeda İlaç Sağlık San. Ticaret Limited Şirketi (“<b>Takeda</b>”) tarafından, 6698 sayılı Kişisel
                Verilerin Korunması Kanunu (“<b>KVKK</b>”) madde 10 uyarınca veri sorumlusu sıfatıyla sizleri kişisel verilerinizin
                işlenmesine ilişkin aydınlatmak maksadıyla hazırlanmıştır.
              </p>
              <p>
                <b>Ne Tür Kişisel Verilerinizi İşliyoruz?</b>
              </p>
              <p>
               “Kişisel veri” ile tarafınıza ilişkin ad-soyad, e-posta adresi, uzmanlık alanı, adres, çalıştığınız kurum, ve biriminiz, kastedilmektedir.
              </p>
              <p>
                <b>Kişisel Verilerinizi Hangi Amaçlarla ve Hukuki Sebeplerle İşliyoruz?</b>
              </p>
              <p>
                Kişisel verileriniz tarafımızca aşağıda belirtilen amaçlarla ve KVKK <b>madde 5/2 (c)</b> uyarınca bir sözleşmenin kurulması
                veya ifasıyla doğrudan doğruya ilgili olması ve <b>madde 5/2(f)</b> uyarınca veri sorumlusu olarak meşru menfaatlerimiz için
                zorunlu olması sebebiyle açık rızanıza ihtiyaç duyulmadan işlenecektir:
              </p>
              <ul>
                <li>
                  Hekimlere yönelik ve özel olarak hazırlanmış hastalık farkındalığı içeriklerinin tarafınıza iletilmesi amacıyla{" "}
                  <a href="/" target="">
                    https://www.yolculugumm.com
                  </a>{" "}
                  internet sitesi üzerinden kaydınızın alınması ve tutulması, söz konusu içeriğin tarafınıza iletilmesi.
                </li>
              </ul>
              <p>
                Kişisel verileriniz tarafımızca aşağıda belirtilen amaçlarla ve KVKK <b>madde 5/2 (ç)</b> uyarınca veri sorumlusu olarak
                hukuki yükümlülüklerimizin yerine getirilebilmesi için zorunlu olması sebebiyle açık rızanıza ihtiyaç duyulmadan işlenecektir:
              </p>
              <ul>
                <li>
                  Beşeri Tıbbi Ürünlerin Tanıtım Faaliyetleri Hakkında Yönetmelik (“<b>Tanıtım</b> <b>Yönetmeliği</b>”) uyarınca
                  Türkiye İlaç ve Tıbbi Cihaz Kurumu’na (“<b>TİTCK</b>”) bildirim yükümlülüğünün yerine getirebilmesi amacıyla,
                </li>
                <li>
                  Takeda ürünleri ile ilgili hastalarınızdan gelebilecek kalite şikâyetlerini Takeda’ya iletmeniz halinde kalite şikâyetlerinin ve
                  uyuşmazlıklarının yönetilmesi, ürünün güvenliğine ilişkin geri bildirimlerin alınması ve ilgili mercilere raporlanması, advers olay
                  bildirimlerinizin işlenmesi, ürünün güvenliğine ilişkin yükümlülüklerimizin yerine getirilmesi amaçlarıyla.
                </li>
              </ul>
              <p>
                Kişisel verileriniz tarafımızca aşağıda belirtilen amaçlarla ve KVKK <b>madde 5/2(f)</b> uyarınca meşru menfaatlerimiz için
                zorunlu olması sebebiyle açık rızanıza ihtiyaç duyulmadan işlenecektir:
              </p>
              <ul>
                <li>
                  Tanıtım Yönetmeliği uyarınca Takeda ürün tanıtım temsilcileri tarafından sanal platformlar üzerinden veya yüz yüze tarafınızla
                  iletişime geçilmesi ve görüşmeler yapılması, bu görüşmelere sizi davet etmek amacıyla,
                </li>
                <li>
                  Takeda ürünlerinin sağlık meslek mensupları nezdindeki algısını ölçmek, Takeda ürünlerinin kullanışlılığına ve hasta memnuniyetine
                  ilişkin geri bildirimlerinizi toplamak ve/veya yeni ürünlere ilişkin görüşler toplamak amaçlarıyla anketler yapmak amacıyla,
                </li>
                <li>
                  Takeda tarafından veya sponsorluğunda düzenlenen yurtiçi ve yurtdışı kongrelere ve ürün tanıtım toplantılarına sizi davet etmek ve
                  bu amaçla tarafınızla iletişime geçmek, Takeda ürünleri ve yürüttüğümüz faaliyetler ile ilgili olarak ihtiyaç duyulması halinde
                  mevzuatın izin verdiği ölçüde ve konularda tarafınızdan danışmanlık ve konuşmacılık hizmetleri almak için tarafınızla iletişime
                  geçmek ve görüşmeler yürütmek amacıyla,
                </li>
                <li>
                  Takeda tarafından gerçekleştirilen klinik araştırmalar ile ilgili size bilgi vermek ve klinik araştırmada görev almanız için sizi
                  davet etmek ve bu amaçla tarafınızla iletişime geçmek,
                </li>
                <li>
                  Takeda ürünleri ve yürüttüğümüz faaliyetler ile ilgili olarak ihtiyaç duyulması halinde mevzuatın izin verdiği ölçüde ve konularda
                  tarafınızdan danışmanlık ve konuşmacılık hizmetleri almak için tarafınızla iletişime geçmek ve görüşmeler yürütmek,
                </li>
                <li>
                  Takeda ürünleri ve bunların endikasyonları kapsamındaki hastalıklar ile hizmetleri ve uzmanlık alanları ile ilgili olarak tarafınıza
                  mevzuatın izin verdiği ölçüde ve kapsamda bilgi sağlamak, tanıtımlar yapmak, tanıtım faaliyetlerimizi planlamak, pazar
                  araştırmalarına katılmanız için sizi davet etmek;
                </li>
                <li>
                  6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili mevzuat uyarınca ticari elektronik ileti gönderilmesine
                  ilişkin izinlerin tarafınızdan ayrıca alınması koşuluyla, tarafınıza ticari elektronik iletiler göndermek amacıyla.
                </li>
              </ul>
              <p>Kişisel verileriniz ayrıca</p>
              <ul>
                <li>
                  Sizlerle olan ilişkilerimizi daha iyi yönetebilmek, iletişim yöntemlerimizi belirlemek ve iyileştirmek ve size özel bilgiler
                  sağlayabilmek için tarafınızla yapılan her türlü iletişim, görüşme ve ziyaretlerimize ilişkin bilgileri Şirketimizin bu amaçlarla
                  kullandığı veri tabanlarına kaydetmek, bunları analiz etmek, kategorize etmek, sınıflandırmak, bunlardan sonuçlar çıkarmak ve
                  saklamak,
                </li>
                <li>
                  Sizlerle olan ilişkilerimizi ve ilgili mevzuatın izin verdiği ölçüde tarafınıza yapılan ziyaretleri daha iyi yönetebilmek için
                  Takeda çalışanlarının performanslarını takip etmek ve değerlendirmek,
                </li>
              </ul>
              <p>
                için KVKK <b>madde 5/1</b> ve <b>madde 6/2</b> kapsamında <b>açık rızanıza dayanarak</b> işlenecektir.
              </p>
              <p>
                <b>Kişisel Verilerinizi Kimlere ve Hangi Amaçlarla Aktarabiliriz?</b>
              </p>
              <p>
                Yukarıda belirtilen amaçları yerine getirebilmek için gerekli olması halinde, Takeda’ya ilettiğiniz kişisel verileriniz TİTCK ve diğer
                ilgili kamu kurum ve kuruluşları, yurtiçinde veya yurtdışında yerleşik bulunan; Takeda’nın dahil olduğu şirketler topluluğu bünyesinde
                yer alan şirketler, üçüncü kişi iş ortaklarımız (bulut bilişim hizmeti aldıklarımız dahil olmak üzere), avukat, mali müşavir,
                denetçiler ve seyahat acenteleri dâhil olmak üzere hizmet / destek / danışmanlık aldığımız üçüncü kişi firmalar ve tedarikçilerimiz,
                düzenlenen bir bilimsel etkinlik/toplantı söz konusu olması halinde etkinliği düzenleyen kurum ve kuruluşlar ve bunların
                hizmet/destek/danışmanlık aldığı üçüncü kişi firmalar ile paylaşılabilecektir.
              </p>
              <p>
                Takeda veri sorumlusu sıfatıyla kişisel verilerinizi bir hizmet ve/veya gizlilik sözleşmesiyle hizmet satın aldığı üçüncü kişi veri
                işleyenler aracılığıyla söz konusu hizmet kapsamında işleyebilir. Bunu yapmak için, bağlı olduğu, dünya genelindeki Takeda adına “veri
                işleyen” sıfatını haiz diğer bağlı şirketlere, bağlı iştiraklere veya harici hizmet sağlayıcılarına kişisel verilerinizi aktarması
                gerekebilir.
              </p>
              <p>
                Ayrıca global bir şirket olarak bilgisayar sistemlerimizin ve alt yapılarımızın yurt dışına bağlı olması sebebi ile ya da yurt dışında
                mukim olan iş ortakları ile yapılacak iş ve işlemler gereği, söz konusu iş ve işlemle sınırlı olmak kaydı ile kişisel verilerin yurt
                dışına aktarılması söz konusu olmaktadır.
              </p>
              <p>
                Kişisel verilerinizin yukarıda belirtilen şekilde yurt dışına aktarılması gerekli önlemlerin alınması{" "}
                <b>ve KVKK madde 9 uyarınca açık rızanızın alınması alınması kaydıyla gerçekleşmektedir. </b>
              </p>
              <p>
                Takeda, verilerinizi aktardığı kişilerin, kişisel verilerinizi, talimatlarımıza ve gizlilik politikalarımıza uygun olarak işlemeyi
                kabul etmesini zorunlu tutar ve verilerinizin güvenli şekilde işlendiğinden emin olmak için uygulanan veri koruma mevzuatında
                belirtilen tüm gerekli güvenlik tedbirlerini ve taahhütnameleri alır.
              </p>
              <p>
                <b>Kişisel Verilerinizi Hangi Yöntemlerle Topluyoruz?</b>
              </p>
              <p>
                Kişisel verileriniz{" "}
                <a href="/" target="">
                  https://www.yolculugumm.com
                </a>{" "}
                internet sitesi üzerinden yaptığınız kayıt, şirketimiz çalışanlarının tarafınızla yaptığı yüz yüze görüşmeler, ziyaretler ya da
                çalıştığınız kurumun veya üye olduğunuz derneklerin internet siteleri gibi kamuya açık internet siteleri aracılığıyla sözlü, yazılı
                veya elektronik ortamda toplanabilir.
              </p>
              <p>
                <b>Kişisel Verilerinize İlişkin Haklarınız Nelerdir?</b>
              </p>
              <p>
                KVKK’nın “ilgili kişinin hakları”nı düzenleyen 11. maddesi kapsamındaki taleplerinizi yazılı olarak Takeda’nın açık adresine (Esentepe
                Mah. River Plaza Büyükdere Cad. Bahar Sok. No: 13 Kat 24 Levent-İstanbul) veya Takeda’ya daha evvel bildirdiğiniz e-posta adresinizi
                kullanmak suretiyle{" "}
                <a href="mailto:turkey.kvkk@takeda.com" data-category="Contact" data-label="turkey.kvkk@takeda.com" data-action="mailto" target="">
                  turkey.kvkk@takeda.com
                </a>{" "}
                e-posta adresine iletebilirsiniz.
              </p>
              <p>KVKK’nın 11. maddesinde anılan kişisel verilerinize ilişkin haklarınız şunlardır:</p>
              <ul>
                <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme, işlendiğine ilişkin bilgi talep etme,</li>
                <li>Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                <li>Kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,</li>
                <li>
                  Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini talep etme, bu hakkınızı kullanmanız halinde
                  bu durumun kişisel verilerinizin aktarıldığı üçüncü kişilere de bildirilmesini isteme,
                </li>
                <li>
                  Kişisel verilerinizin işlenme nedenlerinin ortadan kalkması halinde kişisel verilerinizin silinmesini veya yok edilmesini isteme, bu
                  hakkınızı kullanmanız halinde bu durumun kişisel verilerinizin aktarıldığı üçüncü kişilere de bildirilmesini isteme,
                </li>
                <li>
                  Elde ettiğimiz bilgilerin otomatik sistemler ile analizi yoluyla hakkınızda ortaya çıkan sonuca itiraz etme ve zarara uğramanız
                  halinde tazminat talep etme.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Aydinlatma);
