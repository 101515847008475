import UserSelection from "./components/UserSelection";
import HcpSelection from "./components/HcpSelection";
import Anasayfa from "./pages/Anasayfa";
import Parallax from "./pages/Parallax";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Aydinlatma from "./pages/Aydinlatma";
import UyelikTamam from "./pages/UyelikTamam";
import Podcastler from "./pages/Podcastler";
import VakaHavuzu from "./pages/VakaHavuzu";
import { Route, Switch,BrowserRouter as Router, withRouter} from "react-router-dom";
import SimpleContent from "./pages/SimpleContent";
import Sifremiunuttum from "./pages/Sifremiunuttum";
import Sifremisifirla from "./pages/Sifremisifirla";
import React,{useContext} from "react";
import { UserProvider} from "./UserContext";  
import UserContext from "./UserContext";
import Modal from "./components/Modal";
import Test from "./pages/Test"
import ProtectedRoute from "./tools/ProtectedRoute";
import ScrollToTop from "./tools/ScrollToTop";
import { withCookies } from "react-cookie";

const { REACT_APP_ROOT_DIR } = process.env;

function Routes() {
  const user = useContext(UserContext);
  return (
    <Switch>
      <Route path="/login" ><Login setUser={user.setUser} setToken={user.setSessiontoken} setEnterprise={user.setEnterprise} /></Route>
      <Route path="/sifremiunuttum" ><Sifremiunuttum /></Route>
      <Route path="/sifremisifirla/:email/:stateToken" ><Sifremisifirla /></Route>
      <Route path="/selection"><UserSelection setUser={user.setUser} /></Route>
      <Route path="/hcpselection"><HcpSelection setHcp={user.setHcp} /></Route>
      <Route path="/register" ><Register /> </Route>
      <Route path="/test" ><Test /> </Route>
      <Route path="/uyeliktamam" ><UyelikTamam /></Route>
      <Route path="/aydinlatma-metni"><Aydinlatma /></Route>

      <ProtectedRoute exact path="/" component={Anasayfa} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/parallax" component={Parallax} hcpType={user.hcpType}  userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/vakahavuzu" data={{type : "vakahavuzu", title : "Vaka Havuzu"}} component={VakaHavuzu} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/roportajlar/:slug" data={{type : "roportaj",title : "Videolar"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/roportajlar" data={{type :"roportaj",title : "Videolar"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/yolculugum-mag" data={{type : "magazine",title : "YolculuğuMMag"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/yolculugum-mag/:slug" data={{type : "magazine",title : "YolculuğuMMag"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/multipl-miyelom/:slug" data={{type : "miyelom",title : "Multipl Miyelom"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/multipl-miyelom" data={{type : "miyelom",title : "Multipl Miyelom"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/podcastler/:slug" component={Podcastler} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/podcastler" component={Podcastler} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/yolarkadasi/:slug" data={{type : "yolarkadasi",title : "Yol Arkadaşı"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
      <ProtectedRoute path="/yolarkadasi" data={{type : "yolarkadasi",title : "Yol Arkadaşı"}} component={SimpleContent} hcpType={user.hcpType} userType={user.userType} sessionToken={user.sessionToken} />
    </Switch>
  )
}

class App extends React.Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      theCode : '',
      userType: localStorage.getItem("userType"),
      hcpType :  localStorage.getItem("hcpType"),
      sessionToken: cookies.get("sessionToken") || null,
      modal: { modalOpen: false, modalStyle: { display: "none" }, modalContent: null },
      podcastPlaying : false,
      waveSurfer : null,
    };
    this.setSessiontoken_parent = this.setSessiontoken_parent.bind(this);
    this.setEnterprise_parent = this.setEnterprise_parent.bind(this);
    this.setUsertype_parent = this.setUsertype_parent.bind(this);
    this.setWavesurfer = this.setWavesurfer.bind(this);
  }
  
  openPopup = (cnt, size = 'lg') => {
    this.setState({ modal: { modalOpen: true, modalStyle: { display: "block", paddingRight: "17px" }, modalContent: cnt, xl : size } });
    document.body.classList.add("modal-open");
  };

  closePopup = () => {
    document.body.classList.remove("modal-open");
    this.setState({ modal: { modalOpen: false, modalStyle: { display: "none" }, modalContent: null } });
  };

  setSessiontoken_parent = (value) => {
    const { cookies } = this.props;
    cookies.set("sessionToken", value, { path: REACT_APP_ROOT_DIR });
    this.setState({sessionToken: value}, () => {
      this.props.history.push(REACT_APP_ROOT_DIR);
      window.location.reload();
    })
  };

  setEnterprise_parent = (value) =>{
    const { cookies } = this.props;
    cookies.set("enterprise_id", value, { path: REACT_APP_ROOT_DIR });
  }

  setPodcastplaying = (value) => {
    this.setState({podcastPlaying : value});
  }

  setUsertype_parent = (value) => {
    const { cookies } = this.props;
    if (value === null) {
      cookies.remove("sessionToken", { path: REACT_APP_ROOT_DIR });
      localStorage.removeItem("userType");
      this.setState({ sessionToken: null }, () => {
        this.props.history.push(REACT_APP_ROOT_DIR);
        window.location.reload();
      });

    } else {
      localStorage.setItem("userType", value);
    }
    this.setState({userType: value}, () => {
      this.props.history.push(REACT_APP_ROOT_DIR);
      window.location.reload();
    })
  };

  setHcptype_parent = (value) => {
    localStorage.setItem("hcpType", value);
    this.setState({hcpType: value}, () => {
      this.props.history.push(REACT_APP_ROOT_DIR);
      window.location.reload();
    });
  };

  setWavesurfer = async (value) => {
    this.setState({waveSurfer : value});
  }

  changeTheCode = () => {
    if(this.state.userType === 'halk'){
        return "C-ANPROM/TR/NINL/0010";
    }else if(this.state.userType === 'hcp'){
      return "C-APROM/TR/NINL/0050";
    }else{
      return "";
    }
  }

  render() {
    
    return (
      <>
      <div id='thecode'>{this.changeTheCode()}</div>
      <Router basename={REACT_APP_ROOT_DIR} >
          <ScrollToTop />
          <UserProvider value={{ 
            userType: this.state.userType,
            hcpType : this.state.hcpType,
            sessionToken : this.state.sessionToken,
            openPopup: this.openPopup,
            closePopup: this.closePopup,
            setUser: this.setUsertype_parent,
            setHcp : this.setHcptype_parent,
            podcastPlaying : this.state.podcastPlaying,
            setPodcastplaying : this.setPodcastplaying,
            setSessiontoken : this.setSessiontoken_parent,
            setEnterprise : this.setEnterprise_parent,
            waveSurfer : this.state.waveSurfer,
            setWavesurfer : this.setWavesurfer
          }}>
          <Modal data={this.state.modal} closePopup={this.closePopup} />
          <Routes />      
      </UserProvider>
      </Router>
      </>
    );
  }
}

export default withCookies(withRouter(App));
