import React, { useState, useEffect, useContext } from "react";
import Tilt from "react-tilt";
import UserContext from "../UserContext";
import { Link } from "react-router-dom";
import logobas from "../assets/img/logobas.png";
import logobas_kirmizi from "../assets/img/logobas_kirmizi.png";

const { REACT_APP_API_URL } = process.env;
const axios = require("axios");

export default function Miyeloms() {
  const user = useContext(UserContext);
  const [miyeloms, setMiyeloms] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let catId = user.userType === "halk" ? 3 : 4;
    setLoading(true);
    axios
      .get(REACT_APP_API_URL + "multipl_miyelom?categories=" + catId + "&per_page=100&parent=0&order=asc")
      .then(function (response) {
        let myArr = response.data;
        setMiyeloms(myArr);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [user.userType]);

  return (
    <section id="multipl" className={"container pt-lg-5 pb-5 mt-lg-5 mb-lg-5 "+user.userType }>
      <div className="row text-center">
       <Link to='/multipl-miyelom' className='titleAnchor justify-content-center'>
            {user.userType === "hcp" && <><img src={logobas} alt='seperator' /><h1 className="title r-font">MULTİPL MİYELOM</h1> </>} 
            {user.userType === "halk" && 
            <div className='withHover d-flex flex-row align-items-center'>
              <img src={logobas_kirmizi} alt='seperator' />
              <h1 className="title r-font" style={{  marginBottom: 0}}>MULTİPL MİYELOM</h1> 
              <img src={logobas_kirmizi} alt='seperator' />
            </div>}
             
        </Link>


        <p className="col-sm-8 col-12 mx-auto fs-3 mb-5">
          Multipl Miyelom hastalığı hakkında aklınızdaki soruların yanıtlarına ve merak ettiğiniz bilgilere buradan ulaşabilirsiniz.
        </p>
      </div>
      <div id="multipl_1" className="multipl_1">
        <div className="row justify-content-center" >
        {loading && <div className="lds-spinner" style={{margin : "0 auto"}}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
          {miyeloms.map((x, i) => {
            return (
              <Tilt key={i} className="Tilt col col-lg-3 col-md-6" options={{ max: 25, scale: 1.0, reverse: true }}>
                <Link to={"/multipl-miyelom/" + x.slug}>
                  <div className="Tilt-inner">
                    {x.title.rendered} 
                  </div>
                </Link>
              </Tilt>
            );
          })}
        </div>
      </div>
    </section>
  );
}
