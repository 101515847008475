import logo_giris from "../assets/img/logo_giris.png";
import send from "../assets/img/send.png";
import React from "react";
import axios from "axios";
import {withRouter } from "react-router-dom";
import {Link} from "react-router-dom";
import back from "../assets/img/back.png";
import {sendEvent} from "../tools/analytics";


const { REACT_APP_TAKEDA_API_URL } = process.env;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      verified: false,
      errors: null,
      loading : false
    };
    this.onLogin = this.onLogin.bind(this);
    this.onRegister = this.onRegister.bind(this);

    this.input_username = React.createRef();
    this.input_password = React.createRef();
  }

  onRegister = () => {
    
  }

  componentDidMount(){
    const { hash } = window.location;
    if(hash === "#verified"){
      this.setState({ verified : true});
    }

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        this.onLogin();
      }
    };
    document.addEventListener("keydown", listener);

  }

  onLogin = () => {
    this.setState({errors :null });

    if (this.state.username === "") {
      let us = this.input_username.current;
      us.classList.add('is-invalid');
      us.nextSibling.innerHTML = "Kullanıcı adı yazmadınız";
      us.nextSibling.classList.add('error');
    } else if (this.state.password === "") {
      let ps = this.input_password.current;
      ps.classList.add('is-invalid');
      ps.nextSibling.innerHTML = "Şifre yazmadınız";
      ps.nextSibling.classList.add('error');
      return false;
    } else {
      this.setState({ errors: null,loading:true });

      let data = {
        username: this.state.username,
        password: this.state.password,
      };
      
      var self = this;
      axios
        .post(REACT_APP_TAKEDA_API_URL + "login", data)
        .then(function (response) {
          sendEvent({category: 'User',action: 'Kullanıcı giriş yaptı.'});
          if(response.data.hcpStatus === "Closed - Rejected"){
            self.props.setUser('halk');
          }else{
            self.props.setToken(response.data.sessionToken);      
            if(response.data.enterprise_id !== null){
              self.props.setEnterprise(response.data.enterprise_id);   
            }
         
          }
        })
        .catch(function (error) {
          //console.log(error.response);
          if (error.response && error.response.data) {
            switch (error.response.data.errorCode) {
              case "E0000004":
                self.setState({ errors: "Kullanıcı adı ya da şifre yanlış", loading:false });
                break;
              default:
                self.setState({ errors: "Bir hata oluştu", loading: false });
                break;
            }
          }else{
            self.setState({errors: "Bağlantı hatası. Sunucu ile iletişim kurulamıyor." , loading:false });
          }
        });
        
    }
  };

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    if(nam === "username" && val.length > 0){
      let us = this.input_username.current;
      us.classList.remove('is-invalid');
      us.nextSibling.innerHTML = "Kullanıcı Adı / E-Posta";
      us.nextSibling.classList.remove('error');
    }
    if(nam === "password" && val.length > 0){
      let ps = this.input_password.current;
      ps.classList.remove('is-invalid');
      ps.nextSibling.innerHTML = "Şifre";
      ps.nextSibling.classList.remove('error');
    }
    this.setState({ [nam]: val });
  };
  render() {
    return (
      <div className="loginregister container justify-content-center d-flex flex-column pt-5 pb-5 " >

        <div className="float-start position-absolute mt-3" style={{top : 0}}>
            <Link to={'/selection'}><img src={back} alt="Geri" /></Link>
        </div>


        <div className="row">
          <div className="col text-center">
            <img alt="logo_giris" className="img-fluid" src={logo_giris} />
          </div>
        </div>

          <div className="row mt-5">
            <div className="d-flex flex-column align-items-center w-100">
              <div className="col-lg-8 d-flex flex-column align-items-center">
                <h2>Takeda ID Üye Girişi</h2><br />
                {this.state.verified && <p className='verified'>Üyeliğiniz aktifleştirildi.<br />Aşağıdaki formdan giriş yapabilirsiniz.</p>}
                <p className="text-center">Bu sıradışı yolculukta siz değerli sağlık çalışanlarımızı çok özel deneyimler bekliyor.<br /> Sizin için hazırladığımız içeriklere, dokümanlara ve videolara ulaşmak için lütfen giriş yapınız. </p>
                {this.state.errors && <p className="error">{this.state.errors}</p>}
              
                <div className="form-floating mb-3" style={{width:"100%"}}>
                  <input type="text" ref={this.input_username} name="username" onChange={this.myChangeHandler} id='username' placeholder="Kullanıcı Adı" className="form-control" />
                  <label htmlFor="username">Kullanıcı Adı / E-Posta</label>
                </div>

                <div className="form-floating mb-3" style={{width:"100%"}}>
                  <input type="password" ref={this.input_password}  name="password" onChange={this.myChangeHandler} id='password' placeholder="Şifre" className="form-control" />
                  <label htmlFor="password">Şifre</label>
                </div>
               
                <div className='w-100 row justify-content-between align-items-center'>
                  <div className='col-xl-6 d-flex justify-content-between mb-5'>
                    <button className='dabutton' onClick={()=> {this.props.history.push("/register")}}> Kayıt Ol</button>
                    <button className='dabutton' onClick={()=> {this.props.history.push("/sifremiunuttum")}}>Şifremi Unuttum</button>
                  </div>

                  <div className='col-xl-6 d-flex justify-content-end mb-5'>
                    {this.state.loading && <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                    {!this.state.loading && <button onClick={this.onLogin}> <img src={send} alt='Gönder' /> </button>}
                  </div>
                </div>

              </div>
            </div>
          </div>

      </div>
    );
  }
}

export default withRouter(Login);