import logo_giris from "../assets/img/logo_giris.png";
import React from "react";
import { uzmanliklar } from "../assets/uzmanliklar";
import send from "../assets/img/send.png";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

const _ = require("lodash");
const { REACT_APP_TAKEDA_API_URL } = process.env;

class Register extends React.Component {
  constructor(props) {
    super(props);

    const searchParams = props.location.search;
    this.queryParams = new URLSearchParams(searchParams);

    this.state = {
      ad: "",
      soyad: "",
      email: "",
      uzmanlik: "",
      hastane: "",
      sifre: "",
      sifre2: "",
      customError: "",
      metin: false,
      metin2: false,
      errors: null,
      loading: false,
    };
    this.onRegister = this.onRegister.bind(this);
    this.setField = this.setField.bind(this);
    this.toast = React.createRef();

    this.metin = React.createRef();
    this.metin2 = React.createRef();

    this.fields = [
      { text: "Ad", type: "text", name: "ad", ref: React.createRef() },
      { text: "Soyad", type: "text", name: "soyad", ref: React.createRef() },
      { text: "Kullanıcı Adı / E-Posta", type: "email", name: "email", ref: React.createRef() },
      { text: "Uzmanlık", type: "select", name: "uzmanlik", ref: React.createRef(), options: uzmanliklar },
      { text: "Çalıştığınız Kurum", type: "text", name: "hastane", ref: React.createRef() },
      { text: "Şifre", type: "password", name: "sifre", ref: React.createRef() },
      { text: "Şifre Tekrar", type: "password", name: "sifre2", ref: React.createRef() },
    ];

  }

  componentDidMount() {
    let ad = this.queryParams.get('ad');
    let soyad = this.queryParams.get('soyad');
    let email = this.queryParams.get('email');
    let uzmanlik = this.queryParams.get('uzmanlik');
    let kurum = this.queryParams.get('kurum');

    this.setField("ad",ad);
    this.setField("soyad",soyad);
    this.setField("email",email);
    this.setField("uzmanlik",uzmanlik);
    this.setField("hastane",kurum);
  }

  setField(field,value){
    if(value){
        this.setState({[field] : value}); 
        _.find(this.fields, { name: field }).ref.current.value = value;
    }
  }

  onRegister = () => {
    this.setState({ errors: null });

    if (this.state.ad === "") {
      let us = _.find(this.fields, { name: "ad" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Adınızı yazmadınız";
      us.nextSibling.classList.add("error");
    } else if (this.state.soyad === "") {
      let us = _.find(this.fields, { name: "soyad" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Soyadınızı yazmadınız";
      us.nextSibling.classList.add("error");
    } else if (this.state.email === "") {
      let us = _.find(this.fields, { name: "email" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "E-Posta adresi yazmadınız";
      us.nextSibling.classList.add("error");
    } else if (!this.CheckMail(this.state.email)) {
      let us = _.find(this.fields, { name: "email" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Lütfen geçerli bir e-posta adresi yazınız.";
      us.nextSibling.classList.add("error");
    } else if (this.state.uzmanlik === "") {
      let us = _.find(this.fields, { name: "uzmanlik" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Uzmanlık alanı seçmediniz";
      us.nextSibling.classList.add("error");
    } else if (this.state.hastane === "") {
      let us = _.find(this.fields, { name: "hastane" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Çalıştığınız kurum adını yazmadınız";
      us.nextSibling.classList.add("error");
    } else if (this.state.sifre === "") {
      let us = _.find(this.fields, { name: "sifre" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Şifre yazmadınız";
      us.nextSibling.classList.add("error");
    } else if (this.state.sifre2 === "") {
      let us = _.find(this.fields, { name: "sifre2" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Şifre tekrarını yazmadınız";
      us.nextSibling.classList.add("error");
    } else if (this.state.sifre !== this.state.sifre2) {
      let us = _.find(this.fields, { name: "sifre" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Şifreler uyuşmuyor.";
      us.nextSibling.classList.add("error");
    } else if (!this.CheckPassword(this.state.sifre)) {
      let us = _.find(this.fields, { name: "sifre" }).ref.current;
      us.classList.add("is-invalid");
      us.nextSibling.innerHTML = "Şifreniz en az 1 rakam, 1 büyük harf barındırmalı ve en az 8 karakterden oluşmalıdır. ";
      us.nextSibling.classList.add("error");
    } else if (this.state.metin === false) {
      let us = this.metin.current;
      us.classList.add("invalid");
    } else {
      this.setState({ errors: null, loading: true });

      const data = Object.create(null);
      this.fields.map((x, i) => {
        data[x.name] = this.state[x.name];
      });

      var self = this;
      axios
        .post(REACT_APP_TAKEDA_API_URL + "register", data)
        .then(function (response) {
          console.log("response : " + response);
          self.setState({ errors: null, loading: false });
          self.props.history.push("/uyeliktamam");
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            switch (error.response.data.errorCode) {
              case "E0000001":
                if (error.response.data.errorSummary === "Api validation failed: login") {
                  self.setState({ errors: "Bu kullanıcı daha önce kayıt olmuş.", loading: false });
                }
                if (error.response.data.errorSummary === "Api validation failed: password") {
                  self.setState({ errors: "Şifre gereken zorlukta değil.", loading: false });
                }
                break;
              default:
                self.setState({ errors: "Bir hata oluştu", loading: false, customError: error.response.data.message });
                break;
            }
          } else {
            self.setState({ errors: "Bağlantı hatası. Sunucu ile iletişim kurulamıyor.", loading: false });
          }
        });
    }
  };

  CheckPassword = (inputtxt) => {
    var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    if (inputtxt.match(passw)) {
      return true;
    } else {
      return false;
    }
  };

  CheckMail = (inputtxt) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(inputtxt)) {
      return true;
    } else {
      return false;
    }
  };

  myMetinHandler = (event) => {
    this.setState({ metin: event.target.checked });
    let us = this.metin.current;
    if (event.target.checked) {
      us.classList.remove("invalid");
    } else {
      us.classList.add("invalid");
    }
  };

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    if (nam === "ad" && val.length > 0) {
      let us = _.find(this.fields, { name: "ad" }).ref.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Ad";
      us.nextSibling.classList.remove("error");
    }
    if (nam === "soyad" && val.length > 0) {
      let us = _.find(this.fields, { name: "soyad" }).ref.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Soyad";
      us.nextSibling.classList.remove("error");
    }
    if (nam === "hastane" && val.length > 0) {
      let us = _.find(this.fields, { name: "hastane" }).ref.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Çalıştığınız Kurum";
      us.nextSibling.classList.remove("error");
    }
    if (nam === "email" && val.length > 0) {
      let us = _.find(this.fields, { name: "email" }).ref.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Kullanıcı Adı / E-Posta";
      us.nextSibling.classList.remove("error");
    }
    if (nam === "sifre" && val.length > 0) {
      let us = _.find(this.fields, { name: "sifre" }).ref.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Şifre";
      us.nextSibling.classList.remove("error");
    }
    if (nam === "sifre2" && val.length > 0) {
      let us = _.find(this.fields, { name: "sifre2" }).ref.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Şifre Tekrar";
      us.nextSibling.classList.remove("error");
    }
    if (nam === "uzmanlik" && val.length > 0) {
      let us = _.find(this.fields, { name: "uzmanlik" }).ref.current;
      us.classList.remove("is-invalid");
      us.nextSibling.innerHTML = "Uzmanlık";
      us.nextSibling.classList.remove("error");
    }
    this.setState({ [nam]: val });
  };

  

  render() {
    return (
      <div className="loginregister container justify-content-center d-flex flex-column pt-5 pb-5 ">
        {this.state.customError && (
          <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 5 }}>
            <div id="liveToast" ref={this.toast} className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header">
                <strong className="me-auto">Uyarı</strong>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => this.setState({ customError: "" })}
                  data-bs-dismiss="toast"
                  aria-label="Kapat"
                ></button>
              </div>
              <div className="toast-body" style={{ color: "#f00", fontWeight: "bold" }}>
                {this.state.customError}
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col text-center">
            <img alt="logo_giris" className="img-fluid" src={logo_giris} />
          </div>
        </div>
        <div className="row mt-5 flex-column text-center align-items-center">
          <div className="col-lg-8 d-flex flex-column align-items-center">
            <h2>Kayıt Olun</h2>
            <p>
              Sağlık profesyoneli içeriğine ulaşmak için Takeda ID'ye kaydolun. Takeda tarafından sağlık mensuplarına açılan tüm dijital platformlara
              Takeda ID 'niz ile erişim sağlayabilirsiniz.
            </p>
            {this.state.errors && <p className="error">{this.state.errors}</p>}
            {this.fields.map((x, i) => {
              if (x.type === "select") {
                return (
                  <div key={i} className="form-floating mb-1" style={{ width: "100%" }}>
                    <select
                      ref={x.ref}
                      id={x.name}
                      name={x.name}
                      onChange={this.myChangeHandler}
                      className="form-control"
                      style={{ paddingTop: "14px", marginTop: "18px", paddingBottom: "6px", height: "auto" }}
                    >
                      <option value="">Uzmanlık Seçiniz</option>
                      {x.options.map((a, b) => {
                        return (
                          <option key={b} value={a}>
                            {a}
                          </option>
                        );
                      })}
                    </select>
                    <label htmlFor={x.name}>{x.text}</label>
                  </div>
                );
              } else {
                return (
                  <div key={i} className="form-floating mb-1" style={{ width: "100%" }}>
                    <input
                      type={x.type}
                      ref={x.ref}
                      name={x.name}
                      onChange={this.myChangeHandler}
                      id={x.name}
                      placeholder={x.text}
                      value={x.value}
                      className="form-control"
                    />
                    <label htmlFor={x.name}>{x.text}</label>
                  </div>
                );
              }
            })}

            <div className="mb-5">
              <div className="form-check" ref={this.metin}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="invalidCheck"
                  required
                  defaultChecked={this.state.metin}
                  onChange={this.myMetinHandler}
                />
                <label className="form-check-label" htmlFor="invalidCheck" style={{ textAlign: "left", fontSize: "16px" }}>
                  Aydınlatma Metnini okudum. Kişisel verilerimin{" "}
                  <Link to="/aydinlatma-metni" target="_blank">
                    Aydınlatma Metni’nde
                  </Link>{" "}
                  açık rızama bağlı olarak işlenebileceği belirtilen amaçlarla ve kapsamda Takeda tarafından işlenmesine ve yurtdışına aktarılmasına
                  rıza veriyorum
                </label>
                <div className="invalid-feedback">Bu alanın işaretlenmesi zorunludur.</div>
                <div className="clearfix"></div>
              </div>

              <div className="form-check" ref={this.metin2}>
                <div className="invalid-feedback">Bu alanın işaretlenmesi zorunludur.</div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="1"
                  id="invalidCheck2"
                  required
                  defaultChecked={this.state.metin2}
                  onChange={() => this.setState({ metin: !this.state.metin2 })}
                />
                <label className="form-check-label" htmlFor="invalidCheck2" style={{ textAlign: "left", fontSize: "16px" }}>
                  Takeda ürün ve hizmetlerine ilişkin yapılacak tanıtım, pazarlama ve bilgilendirme faaliyetleri kapsamında tarafıma ticari elektronik
                  iletilerin (SMS veya email ile) gönderilebilmesine izin veriyorum.
                </label>
              </div>
            </div>
            {this.state.loading && (
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}

            <div className="w-100 d-flex justify-content-between">
              <button
                className="dabutton"
                onClick={() => {
                  this.props.history.push("/login");
                }}
              >
                Geri Dön
              </button>
              {!this.state.loading && (
                <button
                  onClick={() => {
                    this.onRegister();
                  }}
                >
                  {" "}
                  <img src={send} />{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);
