import footer_logo from "../assets/img/footer_logo.png";
import footer_logo_mobile from "../assets/img/logo_footer_mobile.png";
import takeda_logo from "../assets/img/takeda.png";

export default function Footer() {
  return (
    <footer>
      <div className="container-fluid">
        <div className="container text-center">
          <div className="mx-auto mt-5 mb-5">
            <img className="img-fluid" src={takeda_logo} alt='Takeda Logo' />
          </div>
          Bu web sitesi sadece bilgilendirme amaçlıdır. Bu sitedeki bilgiler,
          bir hekim veya eczacıya danışmanın yerine geçemez.
          <div className="clearfix"></div>
          Daha fazla bilgi için bir hekime ve / veya bir eczacıya başvurunuz.
          <div className="clearfix"></div>
          Takeda İlaç Sağlık Sanayi Ticaret Limited Şirketi- River Plaza
          Büyükdere Cad. Bahar Sok. No:13 Kat:24 34394
          <div className="clearfix"></div>
          Levent İstanbul / Türkiye Tel:+90 212 401 82 00 -Fax:+90 212 401 83 99
          -Email:tr.info@takeda.com
          <div className="mx-auto mt-5 mb-5">
            <img
              className="img-fluid mx-auto d-none d-sm-block"
              src={footer_logo} alt="Footer logo"
            />
            <img
              className="img-fluid d-sm-none"
              src={footer_logo_mobile} alt="Footer logo mobile"
            />
          </div>
          Tüm hakları saklıdır. 2022.
        </div>
      </div>
    </footer>
  );
}
