var imagePath = process.env.PUBLIC_URL+"/sinema/";

var sahneler = [
  {
    sahneAdi: "giris",
    kareler: [
      { type: "img", file: "1-baz", className: "selector", id: "thebaz", style: {zIndex:10} },
      { style: {zIndex: 11}, type: "div", file: "5-cicek", className: "sagsol" },
      { style: {zIndex: 12}, type: "div", file: "2-posterlight", className: "posterlight isik2" },
      { style: {zIndex: 9}, type: "div", file: "3-solkapi", className: "solkapi" },
      { style: {zIndex: 9}, type: "div", file: "3-sagkapi", className: "sagkapi" },
      { style: {zIndex: 12}, type: "div", file: "4-tabela", className: "tabela" },
      { style: {zIndex: 12}, type: "div", file: "6-buyuksaksi", className: "buyuksaksi" },
      { style: {zIndex: 12}, type: "div", file: "7-lamba", className: "lamba" },
      { style: {zIndex: 13}, type: "div", file: "8-lambaisik", className: "lamba isik" },
      { style: {zIndex: 14}, type: "div", file: "9-taksi", className: "taksi" },
      { style: {zIndex: 14}, type: "div", file: "10-agac", className: "agac" },
      { style: {zIndex: 14}, type: "div", file: "11-kus", className: "kus" }
    ]
  },
  {
    sahneAdi: "fuaye",
    kareler: [
      { type: "img", file: "1-baz", className: "selector", id: "thebaz2", style: {zIndex:1} },
      { type: "div", style: {zIndex: 2}, file: "2-koltukgrubu", className: 'koltukgrubu' },
      { type: "div", style: {zIndex: 2}, file: "3-koltukgrubu-isik", className: 'koltukgrubu isik3' },
     
      { type: "div", style: {zIndex: 3}, file: "4-popcorn", className: 'popcorn' },

      { type: "div", style: {zIndex: 4}, file: "isik1", className: 'fuaye_isik1' },
      { type: "div", style: {zIndex: 6}, file: "isik2", className: 'fuaye_isik2' },

      { type: "div", style: {zIndex: 16}, file: "5-gise-arka", className: 'gisearka' },
      { type: "div", style: {zIndex: 17}, file: "6-gise-on", className: 'giseon' },
      { type: "div", style: {zIndex: 18}, file: "7-bariyer", className: 'bariyer' }
    ]
  },
  {
    sahneAdi: "salon",
    kareler: [
      { type: "img", file: "1-baz", className: "selector", id: "thebaz3",style: {zIndex:1}},
      { type: "div", style: {zIndex: 4} , file: "perde", className: 'salon_anaperde' },
      { type: "div", style: {zIndex: 5} , file: "perde2", className: 'salon_anaperde2' },
      { type: "div", style: {zIndex: 6} , file: "2-koltuk", className: 'salon_koltuk1' },
      { type: "div", style: {zIndex: 7} , file: "3-koltuk", className: 'salon_koltuk2' },
      { type: "div", style: {zIndex: 12} , file: "8-perde", className: 'salon_perde' },
    ]
  },
];

export { sahneler, imagePath } 