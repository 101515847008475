import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";

import InHeader from "../components/InHeader";
import Footer from "../components/Footer";
import UserContext from "../UserContext";
import logobas from "../assets/img/logobas.png";

const _ = require("lodash");
const { REACT_APP_API_URL } = process.env;

const axios = require("axios");


export default function SimpleContent(props) {
  const tarihGoster = (date) => {
    var tarih = new Date(date);
    var ay = tarih.getMonth();
    var yil = tarih.getFullYear();
    var aylar = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];
    return aylar[ay] + " " + yil;
  };

  let { slug } = useParams();

  const user = useContext(UserContext);
  const [icerik, setIcerik] = useState({});
  const [liste, setListe] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumenttype] = useState(null);

  const getContent = (section, extra = "") => {
    setLoading(true);
    axios
      .get(REACT_APP_API_URL + section + "?_embed&per_page=100" + extra)
      .then(function (response) {
        if (response.data.length > 0) {
          setListe(response.data);
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    const getir = () => {
      switch (props.type) {
        case "miyelom":
          let catId = user.userType === "halk" ? 3 : 4;
          getContent("multipl_miyelom", "&categories=" + catId+ "&order=asc");
          setDocumenttype("multipl-miyelom");
          break;
        case "roportaj":
          getContent("roportajlar");
          setDocumenttype("roportajlar");
          break;
        case "yolarkadasi":
          getContent("yol_arkadasim");
          setDocumenttype("yol_arkadasim");
          break;
        default:
          break;
      }
    };
    getir();
  }, []);

  useEffect(() => {
    if (liste.length > 0) {
      if (slug) {
        const foundContent = _.find(liste, function (data) {
          return data.slug === slug;
        });
        if (foundContent) {
          setIcerik(foundContent);
        }
      } else {
        setIcerik(liste[0]);
      }
    }
  }, [liste]);

  useEffect(() => {
    if (slug && liste.length > 0) {
      const foundContent = _.find(liste, function (data) {
        return data.slug === slug;
      });
      if (foundContent) {
        setIcerik(foundContent);
      }
    }
  }, [slug]);

  return (
    <div className={'simpleContent '+user.userType}>
      <InHeader type={props.type} />
      <section className="container-fluid">
        <div className="container">
          <main>

          <div className='titleAnchor justify-content-center my-5'>
            {user.userType === "hcp"  && <img src={logobas} alt='seperator' /> }<h1 className="title r-font">{props.title}</h1>
          </div>
         

            <section className="mb-5">
              <div className="row">
                <div className="col-lg-4 mx-auto mb-md-5 pe-lg-5">
                    <nav className="navbar navbar-expand-sm">
                    <div className="navbar-collapse" id="sidebar">
                      <ul className="navbar-nav lists">
                        {_.filter(liste, function (o) {
                          if(o.parent){
                            return o.parent === 0;
                          }else{
                            return o;
                          }
                        }).map((l, i) => {
                          return (
                            <li key={i} className="item">
                              <Link
                                className={slug && slug === l.slug ? "active" : ""}
                                to={`/${documentType}/${l.slug}`}
                              >
                                <h4>{l.title.rendered}</h4>
                              </Link>
                              {l.parent === 0 &&
                                _.filter(liste, function (o) {
                                  return o.parent === l.id;
                                }).map((ll, a) => {
                                  return (
                                    <div key={a} style={{marginBottom : "1em"}}>
                                      <Link
                                        className={slug && slug === ll.slug ? "active" : ""}
                                        key={a}
                                        to={`/${documentType}/${ll.slug}`}
                                      >
                                        {ll.title.rendered}
                                      </Link>
                                    </div>
                                  );
                                })}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="col-lg-8 ps-lg-5 border-left">
                      {icerik && Object.keys(icerik).length > 0 && (
                        <div>
                          {icerik._embedded && icerik._embedded["wp:featuredmedia"] && (
                            <img
                              className="img-fluid mb-5"
                              src={icerik._embedded["wp:featuredmedia"][0].source_url}
                              alt={icerik.title.renreder}
                            />
                          )}

                          <h1 className="display-5">{icerik.title.rendered}</h1>
                        
                          <br />
                          <div
                            style={{ marginTop: 20 }}
                            dangerouslySetInnerHTML={{ __html: icerik.content.rendered }}
                          />
                        </div>
                      )}
                      {loading && (
                        <div>
                          <h1 className="display-3">Yükleniyor</h1>
                          <div>
                            <div className="lds-spinner">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>{" "}
                          </div>
                        </div>
                      )}
                      {Object.keys(icerik).length === 0 && !loading && (
                        <div>
                          <h1 className="display-3">İçerik bulunamadı</h1>
                          <div> Aradığınız içerik bulunamadı. Lütfen daha farklı parametreler ile tekrar deneyin.</div>
                        </div>
                      )}
                </div>
              </div>
            </section>
          </main>
        </div>
      </section>
      <Footer />
    </div>
  );
}
