import logo_giris from "../assets/img/logo_giris.png";
import send from "../assets/img/send.png";
import React from "react";
import axios from "axios";
import {withRouter } from "react-router-dom";
import {Link} from "react-router-dom";
import back from "../assets/img/back.png";
import {sendEvent} from "../tools/analytics";


const { REACT_APP_TAKEDA_API_URL } = process.env;

class Sifremiunuttum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      errors: null,
      loading : false,
      stateToken : null,
      hasSent : false,
    };
    this.onSend = this.onSend.bind(this);

    this.input_username = React.createRef();
  }

  onRegister = () => {
    
  }

  componentDidMount(){

    const { hash } = window.location;
    if(hash === "#verified"){
      this.setState({ stateToken : hash});
    }

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        this.onLogin();
      }
    };
    document.addEventListener("keydown", listener);

  }

  onSend = () => {
    this.setState({errors :null });

    if (this.state.username === "") {
      let us = this.input_username.current;
      us.classList.add('is-invalid');
      us.nextSibling.innerHTML = "Kullanıcı adı / E-Posta yazmadınız";
      us.nextSibling.classList.add('error');
    } else {
      this.setState({ errors: null,loading:true });

      let data = {
        email: this.state.username
      };
      
      var self = this;
      axios
        .post(REACT_APP_TAKEDA_API_URL + "forgot_password", data)
        .then(function (response) {
          self.setState({hasSent : true });
          //sendEvent({category: 'User',action: 'Kullanıcı giriş yaptı.'});    
        })
        .catch(function (error) {
          console.log(error);
          if (error.response && error.response.data) {
            switch (error.response.data.errorCode) {
              case "E0000095":
                self.setState({ errors: "Sistemde kayıtlı böyle bir kullanıcı bulunamadı.", loading:false });
                break;
              default:
                self.setState({ errors: "Bir hata oluştu", loading: false });
                break;
            }
          }else{
            self.setState({errors: "Bağlantı hatası. Sunucu ile iletişim kurulamıyor." , loading:false });
          }
        });
        
    }
  };

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    if(nam === "username" && val.length > 0){
      let us = this.input_username.current;
      us.classList.remove('is-invalid');
      us.nextSibling.innerHTML = "Kullanıcı Adı / E-Posta";
      us.nextSibling.classList.remove('error');
    }
    this.setState({ [nam]: val });
  };
  render() {
    return (
      <div className="loginregister container justify-content-center d-flex flex-column pt-5 pb-5 " >

        <div className="float-start position-absolute mt-3" style={{top : 0}}>
            <Link to={'/login'}><img src={back} alt="Geri" /></Link>
        </div>


        <div className="row">
          <div className="col text-center">
            <img alt="logo_giris" className="img-fluid" src={logo_giris} />
          </div>
        </div>

          <div className="row mt-5">
            <div className="d-flex flex-column align-items-center w-100">
              <div className="col-lg-8 d-flex flex-column align-items-center">
                <h2>Takeda ID Şifremi Unuttum</h2><br />

                {this.state.hasSent && <p>İşlem başarılı. Şifrenizi e-posta adresinize gelen linke tıklayarak değiştirebilirsiniz. </p>}

                {!this.state.hasSent && 
                <p className="text-center">Lütfen parolanızı sıfırlamak için sisteme kayıtlı e-posta adresinizi girin. </p>
                }
                {this.state.errors && <p className="error">{this.state.errors}</p>}
              
                {!this.state.hasSent && 
                <>
                  <div className="form-floating mb-5 mt-5" style={{width:"100%"}}>
                    <input type="text" ref={this.input_username} name="username" onChange={this.myChangeHandler} id='username' placeholder="Kullanıcı Adı" className="form-control" />
                    <label htmlFor="username">Kullanıcı Adı / E-Posta</label>
                  </div>
                
                  <div className='w-100 row justify-content-between align-items-center'>
                  <div className='col-xl-12 d-flex justify-content-end mb-5'>
                      {this.state.loading && <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                      {!this.state.loading && <button onClick={this.onSend}> <img src={send} alt='Gönder' /> </button>}
                    </div>
                  </div>
                </>
                }

              </div>
            </div>
          </div>

      </div>
    );
  }
}

export default withRouter(Sifremiunuttum);