import lax from './lax.js';

export var s_salon = function () { 
    let salonBas = 2000;
    let salonBit = 3000;


    lax.addElements("#thevideo", {
      scrollY: {
        opacity: [
          [3300, 3400],
          [0, 1],
        ],
      },
    });


  lax.addElements(".salon_perde", {
    scrollY: {
      translateZ: [
        [salonBas, salonBit],
        [0, "elWidth/2.5"],
      ],
    },
  });

  lax.addElements(".salon_shadow", {
    scrollY: {
      translateZ: [
        [salonBas, salonBit],
        [0, "elWidth/3"],
      ],
    },
  });

  lax.addElements(".salon_koltuk2", {
    scrollY: {
      translateZ: [
        [salonBas, salonBit],
        [0, "elWidth/3"],
      ],
    },
  });


  lax.addElements(".salon_koltuk1", {
    scrollY: {
      translateZ: [
        [salonBas, salonBit],
        [0, "elWidth/6"],
      ],
    },
  });
  



  lax.addElements(".salon_anaperde", {
    scrollY: {
      scaleX: [
        [3000, 3500],
        [1, 0.35],
      ],
    },
  });

  lax.addElements(".salon_anaperde2", {
    scrollY: {
      scaleX: [
        [3000, 3500],
        [1, 0.35],
      ],
    },
  });

  
};
