import lax from './lax.js';

export var s_fuaye = function () {
    let fuayeBas = 1200;
    let fuayeBit = 2500;

    lax.addElements(".fuaye", {
        scrollY: {
          opacity: [
            [fuayeBit, fuayeBit+200],
            [1, 0],
          ]
        },
      });


  lax.addElements(".bariyer", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/2.5"],
      ],
    },
  });

  
  lax.addElements(".fuaye_isik1", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/3.5"],
      ],
    },
  });
  lax.addElements(".fuaye_isik2", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/3.9"],
      ],
    },
  });
  lax.addElements(".fuaye_isik3", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/4.3"],
      ],
    },
  });
  lax.addElements(".fuaye_isik4", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/4.7"],
      ],
    },
  });
  lax.addElements(".fuaye_isik5", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/5.1"],
      ],
    },
  });
  lax.addElements(".fuaye_isik6", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/5.5"],
      ],
    },
  });

  lax.addElements(".giseon", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/3"],
      ],
    },
  });

  lax.addElements(".gisearka", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/4"],
      ],
    },
  });

  lax.addElements(".popcorn", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/5"],
      ],
    },
  });

  lax.addElements(".koltukgrubu", {
    scrollY: {
      translateZ: [
        [fuayeBas, fuayeBit],
        [0, "elWidth/6"],
      ],
    },
  });

  
}
