import React, {  useEffect, useState } from "react";
import scroll_down from "../assets/img/scroll_down.png";
import { Link } from "react-router-dom";

export default function Sahne() {

  const [showScroll, setShowscroll] = useState(true);

  /* const handleScroll = () => {
    if (window.pageYOffset > 50) {
      setShowscroll(false);
    } else {
      setShowscroll(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  */

  return (
    <section className="container-fluid">
     {/*  {showScroll && (
        <div className="scrolldown arrow bounce">
          <button
            onClick={() => {
              window.scrollTo(0, 1000);
            }}
          >
            {" "}
            <img src={scroll_down} alt="Aşağı Kaydır" />
          </button>
        </div>
      )}
    */}

      <div className="row">
        <div className="bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/perde.jpg)` }}>
        <Link className="homePerde" to='/parallax' />
        </div>
      </div>
    </section>
  );
}
