import * as React from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import {haritaList,sehirList} from "../tools/haritaList";
import _ from "lodash";
const axios = require("axios");

export default function Harita() {
    const [map,setMap] = React.useState();
    const [selectedCity, setSelectedcity] = React.useState(null);

  const someFunc = async () => {
    const sehirler = [];
    
    for (let i = 0; i < sehirList.length; i++) {
      let url = "https://maps.googleapis.com/maps/api/geocode/json?address="+encodeURI(sehirList[i])+"&key=AIzaSyCf9BfhaZ0PU8rwJMr9d5y7DyA32X7ZiGo";
      await axios.get(url).then(function (response) {
        if(response.data.status === "OK"){
          sehirler.push({sehir : sehirList[i], position : response.data.results[0].geometry.location}) ;
        }
      })
    }
    console.log(sehirler);
  }

  const filtre = () => {
    //var set1 = new Set();
    haritaList.forEach( (x,i)  => {
      let found = _.find(x.address_components, function(o) { return o.types.includes('administrative_area_level_1') ; });
      if(found){
        haritaList[i].sehir = found.long_name;
      }
    });
    console.log(haritaList);
  }

  React.useEffect(() => {
    L.Icon.Default.imagePath = process.env.PUBLIC_URL + "/img/";
    //someFunc();
    //filtre();

  }, []);
  
  const goLocation = (location) => {
    map.map.flyTo([location.lat,location.lng],14,{duration : 1});
  }

  const onChange = (event) => {
    if(event.target.value !== "0"){
    var pos = event.target.value.split(",");
    map.map.flyTo(pos,10,{duration : 1});

    var index = event.nativeEvent.target.selectedIndex;
    var sehir = event.nativeEvent.target[index].text;

    setSelectedcity(sehir);
    }
  }
  

  return (

    <div className="row haritaList halk">
      <div className="col-lg-4 pb-3">
        <h2>Şehrinizi Seçin</h2>
        <select className="form-select form-select-lg mb-3" onChange={onChange} aria-label=".form-select-lg example">
          <option value='0'>Seçiniz</option>
          { sehirList.map( (x,i) => {
            return <option key={i} value={x.position.lat+","+x.position.lng} data-name={x.sehir}>{x.sehir}</option>
          })}
        </select>
        <ol className="list-group lists" style={{ height : 390, overflow : "auto", backgroundColor: '#e0cdbc'}}>
          {  _.filter(haritaList, function(i) {
          if(i.sehir === selectedCity){
            return i
          }
         }).map( (x,i) => {
            return <li key={i} className="list-group-item">
              <button onClick={() => { goLocation(x.position)}} style={{fontSize : "1rem", marginBottom : "1em"}}><b>{x.hastane}</b></button>
              {x.adres && <div><b>Adres :</b> {x.adres}</div>}
              {x.telefon && <div><b>Telefon : </b><a href={`tel:${x.telefon}`}>{x.telefon}</a></div>}
              {x.email && <div><b>E-Posta : </b> <a target="_blank" rel="noreferrer" href={`mailto : ${x.email}`}>{x.email}</a> </div>}
            </li>
         })
         }
            
        </ol>
      </div>
      <div className="col-lg-8">
        <MapContainer center={[39.21, 34.63]} zoom={6} style={{ height: "500px" }} whenCreated={map => setMap({ map })}>
          <TileLayer attribution="&copy;" url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" />
          
          {haritaList.map((x, i) => {
            if(x.position){
            return (
              <Marker position={[x.position.lat,x.position.lng]} key={i}>
                <Popup>
                  <div className="text-center">
                    <b>{x.hastane}</b>
                    <br />
                    {x.adres && <div>Adres : {x.adres}</div>}
                    {x.telefon && <div>Telefon : {x.telefon}</div>}
                    {x.email && (
                      <a target="_blank" rel="noreferrer" href={`mailto : ${x.email}`}>
                        E-Posta Gönderin
                      </a>
                    )}
                  </div>
                </Popup>
              </Marker>
            );
           }
          })}
        </MapContainer>
      </div>
    </div>
  );
}
