import doctor from "../assets/img/doctor.png";
import not_doctor from "../assets/img/not_doctor.png";
import logo_giris from "../assets/img/logo_giris.png";

export default function UserSelection(props) {
  const setUserType = props.setUser;

    return (
      <div className="userSelection container justify-content-center d-flex flex-column" style={{ height: "100vh" }}>
        <div className="row mb-5">
          <div className="col text-center">
            <img alt="logo_giris" className="img-fluid" src={logo_giris} />
          </div>
        </div>

        <div className="row mt-5 justify-content-center">
          <div className="col-md-8 d-flex justify-content-evenly">
            <div className="row justify-content-center w-100">
              <button className="col-lg-5  col-12 text-center" onClick={() => setUserType("hcp")}>
                <img alt="sağlık çalışanı" className="img-fluid" src={doctor} />
                <h2 className="mt-2 text-dark">
                  Sağlık <br /> Çalışanıyım
                </h2>
              </button>

              <div className="col-lg-1">
                <div style={{ background: "#000", width: "1px", height:"100%", margin : "0 auto" }} className='d-none d-lg-block d-xl-block'></div>
                <div style={{ background: "#000", height: "1px", width:"70%", margin : "30px auto" }} className='d-block d-lg-none'></div>
              </div>

              <button className="col-lg-5  col-12 text-center" onClick={() => setUserType("halk")}>
                <img alt="sağlık çalışanı olmayan" className="img-fluid" src={not_doctor} />
                <h2 className="mt-2 text-dark">
                  Sağlık Çalışanı <br /> Değilim
                </h2>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}
